<script lang="ts">
  import BaseSvg from "~/ui/lib/BaseSvg.svelte";
</script>

<BaseSvg viewBox="0 0 446 446" {...$$props}>
  <g transform="matrix(1,0,0,1,-1780.85,-1060.26)">
    <path
      d="M2107.48,1193.89C2107.48,1171.02 2088.91,1152.45 2066.04,1152.45L1893.58,1152.45C1870.71,1152.45 1852.14,1171.02 1852.14,1193.89L1852.14,1366.37C1852.14,1389.24 1870.71,1407.81 1893.58,1407.81L2066.04,1407.81C2088.91,1407.81 2107.48,1389.24 2107.48,1366.37L2107.48,1193.89ZM2107.48,1333.32L2107.48,1226.76L2174.3,1202.37C2174.3,1202.37 2181.73,1198.32 2187.32,1203.11C2192.92,1207.9 2192.41,1213.54 2192.41,1213.54L2192.41,1346.81C2192.41,1346.81 2193.41,1353.79 2186.76,1357.84C2180.12,1361.9 2174.79,1358.63 2174.79,1358.63L2107.65,1333.32Z"
    />
  </g>
</BaseSvg>
