<script lang="ts">
  import BaseSvg from "~/ui/lib/BaseSvg.svelte";
</script>

<BaseSvg viewBox="0 0 446 446" {...$$props}>
  <g transform="matrix(1,0,0,1,-1680.29,-1606.52)">
    <g transform="matrix(1,0,0,1,-42.996,-59.9047)">
      <g transform="matrix(1.09147,0,0,1.09147,382.662,-193.399)">
        <path
          d="M1455.8,1732.15C1440.19,1730.06 1424.37,1730.06 1408.75,1732.15C1400.38,1747.18 1393.43,1762.85 1387.92,1779.15C1382.68,1780.96 1377.55,1783.08 1372.57,1785.51C1357.14,1777.88 1341.15,1771.72 1324.6,1767.01C1312.08,1776.57 1300.89,1787.76 1291.33,1800.28C1296.04,1816.83 1302.21,1832.82 1309.84,1848.24C1307.4,1853.23 1305.28,1858.36 1303.48,1863.6C1287.17,1869.11 1271.51,1876.06 1256.47,1884.43C1254.38,1900.05 1254.38,1915.87 1256.47,1931.48C1271.51,1939.85 1287.17,1946.8 1303.48,1952.31C1305.28,1957.56 1307.4,1962.68 1309.84,1967.67C1302.21,1983.09 1296.04,1999.08 1291.33,2015.63C1300.89,2028.15 1312.08,2039.34 1324.6,2048.9C1341.15,2044.19 1357.14,2038.03 1372.57,2030.4C1377.55,2032.83 1382.68,2034.95 1387.92,2036.76C1393.43,2053.06 1400.38,2068.72 1408.75,2083.76C1424.37,2085.85 1440.19,2085.85 1455.8,2083.76C1464.17,2068.72 1471.12,2053.06 1476.63,2036.76C1481.88,2034.95 1487,2032.83 1491.99,2030.4C1507.41,2038.03 1523.4,2044.19 1539.95,2048.9C1552.47,2039.34 1563.66,2028.15 1573.22,2015.63C1568.51,1999.08 1562.35,1983.09 1554.72,1967.67C1557.15,1962.68 1559.27,1957.56 1561.08,1952.31C1577.38,1946.8 1593.05,1939.85 1608.08,1931.48C1610.17,1915.87 1610.17,1900.05 1608.08,1884.43C1593.05,1876.06 1577.38,1869.11 1561.08,1863.6C1559.27,1858.36 1557.15,1853.23 1554.72,1848.24C1562.35,1832.82 1568.51,1816.83 1573.22,1800.28C1563.66,1787.76 1552.47,1776.57 1539.95,1767.01C1523.4,1771.72 1507.41,1777.88 1491.99,1785.51C1487,1783.08 1481.88,1780.96 1476.63,1779.15C1471.12,1762.85 1464.17,1747.18 1455.8,1732.15ZM1432.28,1847.03C1465.9,1847.03 1493.2,1874.33 1493.2,1907.95C1493.2,1941.58 1465.9,1968.88 1432.28,1968.88C1398.65,1968.88 1371.35,1941.58 1371.35,1907.95C1371.35,1874.33 1398.65,1847.03 1432.28,1847.03Z"
        />
      </g>
    </g>
  </g>
</BaseSvg>
