<script lang="ts">
  import BaseSvg from "~/ui/lib/BaseSvg.svelte";
</script>

<BaseSvg viewBox="0 0 446 446" {...$$props}>
  <g transform="matrix(1,0,0,1,-1174.48,-1007.26)">
    <g transform="matrix(1,0,0,1,-505.813,0)">
      <g transform="matrix(1,0,0,1,505.813,0)">
        <path
          d="M1307.33,1350.32L1205.33,1452.59L1174.48,1421.74L1590.29,1007.26L1619.91,1036.89L1476.31,1180.88L1476.31,1229.92C1476.31,1273.62 1440.84,1309.09 1397.15,1309.09C1383.11,1309.09 1369.93,1305.43 1358.49,1299.01L1341.02,1316.53C1355.15,1324.98 1373.44,1330.49 1397.15,1330.49C1498.66,1330.49 1501.02,1229.4 1501.02,1229.4L1546.84,1230.45C1546.84,1230.45 1543.89,1360.69 1420.06,1375.04L1420.06,1410.24L1374.23,1410.24L1374.23,1375.04C1346.18,1371.79 1324.34,1362.6 1307.33,1350.32ZM1257.47,1280.33C1247.96,1252.98 1247.45,1230.45 1247.45,1230.45L1293.27,1229.4C1293.27,1229.4 1293.4,1234.79 1294.84,1243.08L1257.47,1280.33ZM1317.98,1220.02L1317.98,1139.77C1317.98,1096.07 1353.45,1060.6 1397.15,1060.6C1419.38,1060.6 1439.48,1069.78 1453.87,1084.56L1317.98,1220.02Z"
        />
      </g>
    </g>
  </g>
</BaseSvg>
