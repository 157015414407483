<script lang="ts">
  import BaseSvg from "~/ui/lib/BaseSvg.svelte";
</script>

<BaseSvg viewBox="0 0 446 446" {...$$props}>
  <g transform="matrix(1,0,0,1,-1430.44,-1053.77)">
    <path
      d="M1501.9,1332.53L1501.9,1187.09C1501.9,1187.09 1500.37,1172.64 1512.55,1159.63C1527.35,1143.84 1541.12,1145.21 1541.12,1145.21L1690.94,1145.21L1501.9,1332.53Z"
    />
    <path
      d="M1846.25,1053.77L1875.88,1083.4L1757.17,1202.11L1757.17,1220.28L1824,1195.88C1824,1195.88 1831.42,1191.83 1837.02,1196.62C1842.61,1201.42 1842.11,1207.05 1842.11,1207.05L1842.11,1340.32C1842.11,1340.32 1843.11,1347.3 1836.46,1351.36C1829.81,1355.41 1824.49,1352.14 1824.49,1352.14L1757.35,1326.83L1757.35,1360.66C1757.35,1360.66 1759.16,1374.75 1746.61,1388.28C1734.06,1401.81 1720.07,1402.34 1720.07,1402.34L1558.06,1402.34L1461.3,1499.1L1430.44,1468.25L1846.25,1053.77Z"
    />
  </g>
</BaseSvg>
