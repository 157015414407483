<script lang="ts">
  import BaseSvg from "~/ui/lib/BaseSvg.svelte";
</script>

<BaseSvg viewBox="0 0 446 446" {...$$props}>
  <g transform="matrix(1,0,0,1,-1680.29,-1007.26)">
    <g>
      <path
        d="M1880.04,1375.04C1756.22,1360.69 1753.26,1230.45 1753.26,1230.45L1799.09,1229.4C1799.09,1229.4 1801.44,1330.49 1902.96,1330.49C2004.48,1330.49 2006.83,1229.4 2006.83,1229.4L2052.65,1230.45C2052.65,1230.45 2049.7,1360.69 1925.88,1375.04L1925.88,1410.24L1880.04,1410.24L1880.04,1375.04Z"
      />
      <g transform="matrix(1,0,0,0.538482,493.195,640.079)">
        <path
          d="M1488.93,1095.38L1488.93,927.954C1488.93,846.812 1453.46,780.935 1409.76,780.935C1366.07,780.935 1330.6,846.812 1330.6,927.954L1330.6,1095.38C1330.6,1176.52 1366.07,1242.4 1409.76,1242.4C1453.46,1242.4 1488.93,1176.52 1488.93,1095.38Z"
        />
      </g>
    </g>
  </g>
</BaseSvg>
